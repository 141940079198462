<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <h5 class="text-h3 text-typo font-weight-bold ms-4 mb-3">
            {{ $t("ticket.Tickets") }}
          </h5>
        </v-col>
        <!-- subjects list -->
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("ticket.Tickets") }}</v-toolbar-title>
                <button class="py-5 mb-0 ms-auto" @click="add_subject()">
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <FormFilter
                :filters="filters"
                @get_data="get_tickets"
                @reset="resetFilters"
              ></FormFilter>
              <Table
                :loading="loading"
                @action="action"
                :btns="data"
                :headers="header"
                :items="tickest_customer"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/Table.vue";
import Notify from "../Components/Old/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import FormFilter from "../Components/Filter.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("ticket");
export default {
  name: "subject-Page",
  components: {
    Table,
    Notify,
    Modal,
    FormFilter,
  },
  data() {
    return {
      filters: [],
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      data: [
        {
          type: "icon",
          text: "details",
          color: "bg-gradient-success",
          icon: "mdi-eye",
        },
        // {type:'icon',text : "destroy",color:'bg-gradient-danger',icon:'mdi-delete'},
      ],

      header: [
        {
          text: this.$i18n.t("ticket.content"),
          value: "content",
          align: "center",
        },
        {
          text: this.$i18n.t("ticket.importance_level"),
          value: "chips_level",
          align: "center",
        },
        {
          text: this.$i18n.t("order.status"),
          value: "status_text",
          align: "center",
        },
        {
          text: this.$i18n.t("ticket.subject"),
          value: "ticket_subjects.subject",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      tickets: [],
      list_of_subjects: [],
      form_data: {
        attachment: null,
        content: "",
        subject_id: "",
      },
      form_style: [
        {
          col: "12",
          type: "select",
          type_select: "single",
          label: this.$i18n.t("ticket.subject"),
          value_text: "subject_id",
          items: [],
          error: null,
          title_select: "subject",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "textarea",
          label: this.$i18n.t("ticket.content"),
          error: null,
          value_text: "content",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "file",
          accept: "image/*",
          label: this.$i18n.t("ticket.attachement"),
          error: null,
          value_text: "attachment",
          type_file: "single",
          rules: [
            (value) =>
              !value ||
              value.size < 2000000 ||
              this.$i18n.t("form.file size should be less than 2 MB"),
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["tickest_customer"]),
  },
  methods: {
    ...mapActions(["getTicketsCustomer"]),
    get_tickets(reset = false) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        console.log(this.filters);
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      this.getTicketsCustomer(formData).then((response) => {
        // console.log(response);
        this.list_of_subjects = response.data.subjects;
        this.loading = false;
        if (reset) this.filters = response.data.filters;
      });
    },
    action(id, name) {
      if (name == "details") {
        this.details(id);
      }
    },
    details(id) {
      this.$router.push({ path: `/tickets/detail/${id.toString()}` });
    },
    add_subject() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("ticket.create new Ticket");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.state.form.style_form[0].items = this.list_of_subjects;
      this.$store.commit("form/INFO_CALL", {
        name: "ticket/addTicket",
      });
    },
    resetFilters() {
      this.get_tickets(true);
    },
  },
  mounted() {
    this.get_tickets(true);
    document.title = this.$i18n.t("ticket.Tickets");
  },
};
</script>
